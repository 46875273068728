import { all } from 'redux-saga/effects';
import authSagas from '@iso/redux/_app/auth/saga';
import profileSaga from '@iso/redux/_app/profile/saga';
import userSaga from '@iso/redux/_app/admin/user/saga';
import cargosSaga from '@iso/redux/_app/admin/cargos/saga';
import gruposSaga from '@iso/redux/_app/admin/grupos/saga';
import empresaSaga from '@iso/redux/_app/admin/empresa/saga';
import setorSaga from '@iso/redux/_app/admin/empresa/params/setor/saga';
import orcamentosSaga from '@iso/redux/_app/admin/orcamentos/saga';
import pesquisaSaga from '@iso/redux/_app/pesquisa/params/pesquisa/saga';
import empresaPatrocinadoraSaga from '@iso/redux/_app/pesquisa/params/empresaPatrocinadora/saga';
import ramoAtividadeSaga from '@iso/redux/_app/admin/empresa/params/ramoAtividade/saga';
import contactSagas from '@iso/redux/contacts/saga';
import mailSagas from '@iso/redux/mail/saga';
import notesSagas from '@iso/redux/notes/saga';
import todosSagas from '@iso/redux/todos/saga';
import cardsSagas from '@iso/redux/card/saga';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    profileSaga(),
    userSaga(),
    cargosSaga(),
    gruposSaga(),
    empresaSaga(),
    setorSaga(),
    ramoAtividadeSaga(),
    contactSagas(),
    mailSagas(),
    notesSagas(),
    todosSagas(),
    cardsSagas(),
    pesquisaSaga(),
    empresaPatrocinadoraSaga(),
    orcamentosSaga()
  ]);
}
