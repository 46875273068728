export const PUBLIC_ROUTE = {
  LANDING: '/',
  SIGN_IN: '/signin',
  SIGN_UP: '/signup',
  FORGET_PASSWORD: '/forgotpassword',
  RESET_PASSWORD: '/recoverPassword/token/:token',
  PAGE_404: '/404',
  PAGE_500: '/500',
  AUTH0_CALLBACK: '/auth0loginCallback',
};

