const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',

  SIGNUP_REQUEST: 'SIGNUP_REQUEST',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  SIGNUP_FAILURE: 'SIGNUP_FAILURE',

  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: (credentials) => ({
    type: actions.LOGIN_REQUEST,
    payload: {
      email: credentials.email,
      password: credentials.password
    },
  }),
  logout: () => ({
    type: actions.LOGOUT,
  }),
  signUp: (user) => ({
    type: actions.SIGNUP_REQUEST,
    payload: user
  }),
};
export default actions;
