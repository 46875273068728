const empresaActions = {
  CREATE_EMPRESA_START: 'CREATE_EMPRESA_START',
  CREATE_EMPRESA_SUCCESS: 'CREATE_EMPRESA_SUCCESS',
  CREATE_EMPRESA_FAILURE: 'CREATE_EMPRESA_FAILURE',
  FETCH_EMPRESA_DATA_START: 'FETCH_EMPRESA_DATA_START',
  FETCH_EMPRESA_DATA_SUCCESS: 'FETCH_EMPRESA_DATA_SUCCESS',
  FETCH_EMPRESA_DATA_FAILURE: 'FETCH_EMPRESA_DATA_FAILURE',
  FETCH_EMPRESA_BY_UID_DATA_START: 'FETCH_EMPRESA_BY_UID_DATA_START',
  FETCH_EMPRESA_BY_UID_DATA_SUCCESS: 'FETCH_EMPRESA_BY_UID_DATA_SUCCESS',
  FETCH_EMPRESA_BY_UID_DATA_FAILURE: 'FETCH_EMPRESA_BY_UID_DATA_FAILURE',
  FETCH_ALL_EMPRESAS_DATA_START: 'FETCH_ALL_EMPRESAS_DATA_START',
  FETCH_ALL_EMPRESAS_DATA_SUCCESS: 'FETCH_ALL_EMPRESAS_DATA_SUCCESS',
  FETCH_ALL_EMPRESAS_DATA_FAILURE: 'FETCH_ALL_EMPRESAS_DATA_FAILURE',

  UPDATE_EMPRESA_DATA_REQUEST: 'UPDATE_EMPRESA_DATA_REQUEST',
  UPDATE_EMPRESA_DATA_SUCCESS: 'UPDATE_EMPRESA_DATA_SUCCESS',
  UPDATE_EMPRESA_DATA_FAILURE: 'UPDATE_EMPRESA_DATA_FAILURE',
  
  DELETE_EMPRESA_REQUEST: 'DELETE_EMPRESA_REQUEST',
  DELETE_EMPRESA_SUCCESS: 'DELETE_EMPRESA_SUCCESS',
  DELETE_EMPRESA_FAILURE: 'DELETE_EMPRESA_FAILURE',

  createEmpresaStart: empresa => ({
    type: empresaActions.CREATE_EMPRESA_START,
    payload: empresa,
  }),
  fetchAllEmpresasDataStart: () => ({
    type: empresaActions.FETCH_ALL_EMPRESAS_DATA_START,
  }),
  fetchAllEmpresasDataSuccess: empresa => ({
    type: empresaActions.FETCH_ALL_EMPRESAS_DATA_SUCCESS,
    payload: empresa,
  }),
  fetchAllEmpresasDataFailure: error => ({
    type: empresaActions.FETCH_ALL_EMPRESAS_DATA_FAILURE,
    payload: error,
  }),
  fetchEmpresaDataStart: id => ({
    type: empresaActions.FETCH_EMPRESA_DATA_START,
    payload: id,
  }),
  fetchEmpresaDataSuccess: empresa => ({
    type: empresaActions.FETCH_EMPRESA_DATA_SUCCESS,
    payload: empresa,
  }),
  fetchEmpresaDataFailure: error => ({
    type: empresaActions.FETCH_EMPRESA_DATA_FAILURE,
    payload: error,
  }),
  updateEmpresa: empresa => ({
    type: empresaActions.UPDATE_EMPRESA_DATA_REQUEST,
    payload: empresa,
  }),
  updateEmpresaFailure: error => ({
    type: empresaActions.UPDATE_EMPRESA_DATA_FAILURE,
    payload: error,
  }),
  deleteRequest: empresa => ({
    type: empresaActions.DELETE_EMPRESA_REQUEST,
    payload: empresa,
  }),
  deleteFailure: error => ({
    type: empresaActions.DELETE_EMPRESA_FAILURE,
    payload: error,
  }),
};

export default empresaActions;
