// saga.js
import { all, takeEvery, put, call } from 'redux-saga/effects';
import empresaPatrocinadoraActions from './actions';
import axios from '@iso/config/axios.config';


function* fetchEmpresaPatrocinadoraDataRequest() {
  try {
    let { data } = yield call(axios.get, 'api/v1/empresaPatrocinadora')
    yield put({
      type: empresaPatrocinadoraActions.FETCH_EMPRESA_PATROCINADORA_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: empresaPatrocinadoraActions.FETCH_EMPRESA_PATROCINADORA_DATA_FAILURE,
      // payload: error.response.data,
      payload: error,
    });
  }
}

export default function* empresaPatrocinadoraSaga() {
  yield all([
    takeEvery(empresaPatrocinadoraActions.FETCH_EMPRESA_PATROCINADORA_DATA_START, fetchEmpresaPatrocinadoraDataRequest),
  ]);
}
