import profileActions from './actions';
import authActions from '../auth/actions';

const INITIAL_DATA = {
  data: null,
  loading: true,
  error: null,
  selectedEmpresa: {}
};
export default function profileReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case profileActions.FETCH_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case authActions.SIGNUP_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case profileActions.FETCH_PROFILE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case profileActions.UPDATE_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
      case profileActions.UPDATE_PROFILE_DATA_FAILURE:
        return {
          ...state,
          loadingData: false,
          error: action.payload,
        };
      case profileActions.UPDATE_PROFILE_DATA_SUCCESS:
        return {
          ...state,
          data: action.payload,
          loadingData: false,
          error: null
        };  
      case profileActions.SET_SELECTED_EMPRESA:
        return {
          ...state,
          selectedEmpresa: action.payload
        };  
      case profileActions.LOGOUT:
        return {
          ...INITIAL_DATA
        };  
    default:
      return state;
  }
}
