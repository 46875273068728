import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';

import { getToken, clearToken } from '@iso/lib/helpers/utility';
import actions from './actions';
import axios from '../../../config/axios.config';

const history = createBrowserHistory();

export function* loginRequest() {
  yield takeEvery(actions.LOGIN_REQUEST, function* ({ payload }) {

    try {
      let { headers, data } = yield call(axios.post, '/login', payload)
      if(headers.authorization){
        yield put({
          type: actions.LOGIN_SUCCESS,
          token: headers.authorization,
        });  
      } else {
        yield put({
          type: actions.LOGIN_ERROR,
          error: true,
        });  
      }
    } catch (error) {
      yield put({
        type: actions.LOGIN_ERROR,
        error: error.response.data,
      });
    }
  }
  );
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
    yield localStorage.setItem('id_token', payload.token);
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () { });
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    yield clearToken();
    history.push('/');
  });
}

export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const token = getToken().get('idToken');
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        profile: 'Profile',
      });
    }
  });
}

export function* signUpRequest() {
  yield takeEvery(actions.SIGNUP_REQUEST, function* ({ payload }) {

    try {
      let {data} = yield call(axios.post, 'api/v1/user/create', payload)
      yield put({
        type: actions.SIGNUP_SUCCESS,
        payload: data,
      });
      yield put({
        type: actions.LOGIN_REQUEST,
        payload: {
          email: payload.email,
          password: payload.password
        },
      });
    } catch (error) {
      yield put({
        type: actions.SIGNUP_FAILURE,
        error: error.response.data,
      });
    }
  }
  );
}

export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
    fork(signUpRequest),
  ]);
}
