import orcamentosActions from './actions';

const INITIAL_DATA = {
  data: null,
  loading: true,
  error: null,

  deleteLoading: false,
  deleteError: null,
};
export default function orcamentosReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case orcamentosActions.FETCH_ALL_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case orcamentosActions.FETCH_ALL_FAILURE:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: action.error,
      };
    case orcamentosActions.DELETE_START:
      return {
        ...state,
        deleteLoading: true,
        deleteError: null,
      };
    case orcamentosActions.DELETE_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteError: null,
      };
    case orcamentosActions.DELETE_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error,
      };
    default:
      return state;
  }
}
