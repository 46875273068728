const profileActions = {
  FETCH_PROFILE_DATA_START: 'FETCH_PROFILE_DATA_START',
  FETCH_PROFILE_DATA_SUCCESS: 'FETCH_PROFILE_DATA_SUCCESS',
  FETCH_PROFILE_DATA_FAILURE: 'FETCH_PROFILE_DATA_FAILURE',
  UPDATE_PROFILE_DATA_REQUEST: 'UPDATE_PROFILE_DATA_REQUEST',
  UPDATE_PROFILE_DATA_SUCCESS: 'UPDATE_PROFILE_DATA_SUCCESS',
  UPDATE_PROFILE_DATA_FAILURE: 'UPDATE_PROFILE_DATA_FAILURE',
  SET_SELECTED_EMPRESA: 'SET_SELECTED_EMPRESA',
  LOGOUT: 'LOGOUT',
  
  fetchProfileDataStart: () => ({
    type: profileActions.FETCH_PROFILE_DATA_START,
  }),
  fetchProfileDataSuccess: profile => ({
    type: profileActions.FETCH_PROFILE_DATA_SUCCESS,
    payload: profile,
  }),
  fetchProfileDataFailure: error => ({
    type: profileActions.FETCH_PROFILE_DATA_FAILURE,
    payload: error,
  }),
  updateProfile: profile => ({
    type: profileActions.UPDATE_PROFILE_DATA_REQUEST,
    payload: profile,
  }),
  updateProfileFailure: error => ({
    type: profileActions.UPDATE_PROFILE_DATA_FAILURE,
    payload: error,
  }),
  updateProfileSuccess: data => ({
    type: profileActions.UPDATE_PROFILE_DATA_SUCCESS,
    payload: data,
  }),
  setSelectedEmpresa: data => ({
    type: profileActions.SET_SELECTED_EMPRESA,
    payload: data,
  }),
  logout: () => ({
    type: profileActions.LOGOUT
  }),
};

export default profileActions;
