import actions from './actions';

const INITIAL_DATA = {
  data: null,
  loading: true,
  error: null,
};
export default function setorReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case actions.CREATE_SUCCESS:
      return {
        ...state,
        data: { ramos: [action.payload, ...state.data.ramos] },
        loadingData: false,
        error: null,
      };
    case actions.CREATE_FAILURE:
      return {
        ...state,
        data: null,
        loadingData: false,
        error: action.payload,
      };

    case actions.UPDATE_SUCCESS:
      let index = state.data.ramos.findIndex(x => x.id === action.payload.id);
      state.data.ramos[index] = action.payload;
      return {
        ...state,
        data: { ramos: [action.payload, ...state.data.ramos] },
        loadingData: false,
        error: null,
      };
    case actions.UPDATE_FAILURE:
      return {
        ...state,
        data: null,
        loadingData: false,
        error: action.payload,
      };
    case actions.FETCH_ALL_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case actions.FETCH_ALL_FAILURE:
      return {
        ...state,
        data: null,
        loadingData: false,
        error: action.payload,
      };
    case actions.DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actions.DELETE_FAILURE:
      return {
        ...state,
        loadingData: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
