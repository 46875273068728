const v = '_SETOR'
const setorActions = {
  CREATE_REQUEST: 'CREATE_REQUEST' + v,
  CREATE_SUCCESS: 'CREATE_SUCCESS' + v,
  CREATE_FAILURE: 'CREATE_FAILURE' + v,
  FETCH_REQUEST: 'FETCH_REQUEST' + v,
  FETCH_SUCCESS: 'FETCH_SUCCESS' + v,
  FETCH_FAILURE: 'FETCH_FAILURE' + v,
  FETCH_ALL_REQUEST: 'FETCH_ALL_REQUEST' + v,
  FETCH_ALL_SUCCESS: 'FETCH_ALL_SUCCESS' + v,
  FETCH_ALL_FAILURE: 'FETCH_ALL_FAILURE' + v,
  UPDATE_REQUEST: 'UPDATE_REQUEST' + v,
  UPDATE_SUCCESS: 'UPDATE_SUCCESS' + v,
  UPDATE_FAILURE: 'UPDATE_FAILURE' + v,
  DELETE_REQUEST: 'DELETE_REQUEST' + v,
  DELETE_SUCCESS: 'DELETE_SUCCESS' + v,
  DELETE_FAILURE: 'DELETE_FAILURE' + v,

  createRequest: data => ({
    type: setorActions.CREATE_REQUEST,
    payload: data,
  }),
  fetchAllRequest: () => ({
    type: setorActions.FETCH_ALL_REQUEST,
  }),
  fetchAllSuccess: data => ({
    type: setorActions.FETCH_ALL_SUCCESS,
    payload: data,
  }),
  fetchAllFailure: error => ({
    type: setorActions.FETCH_ALL_FAILURE,
    payload: error,
  }),
  fetchRequest: id => ({
    type: setorActions.FETCH_REQUEST,
    payload: id,
  }),
  fetchSuccess: data => ({
    type: setorActions.FETCH_SUCCESS,
    payload: data,
  }),
  fetchFailure: error => ({
    type: setorActions.FETCH_FAILURE,
    payload: error,
  }),
  updateRequest: data => ({
    type: setorActions.UPDATE_REQUEST,
    payload: data,
  }),
  updateFailure: error => ({
    type: setorActions.UPDATE_FAILURE,
    payload: error,
  }),
  deleteRequest: data => ({
    type: setorActions.DELETE_REQUEST,
    payload: data,
  }),
  deleteFailure: error => ({
    type: setorActions.DELETE_FAILURE,
    payload: error,
  }),
};

export default setorActions;
