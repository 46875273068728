const orcamentosActions = {
  FETCH_ALL_START: 'FETCH_ALL_START',
  FETCH_ALL_SUCCESS: 'FETCH_ALL_SUCCESS',
  FETCH_ALL_FAILURE: 'FETCH_ALL_FAILURE',

  DELETE_START: 'DELETE_START',
  DELETE_SUCCESS: 'DELETE_SUCCESS',
  DELETE_FAILURE: 'DELETE_FAILURE',

  fetchAllOrcamentosStart: () => ({
    type: orcamentosActions.FETCH_ALL_START,
  }),
  fetchAllOrcamentosSuccess: grupos => ({
    type: orcamentosActions.FETCH_ALL_SUCCESS,
    payload: grupos,
  }),
  fetchAllOrcamentosFailure: error => ({
    type: orcamentosActions.FETCH_ALL_FAILURE,
    payload: error,
  }),

  deleteOrcamentoStart: (data) => ({
    type: orcamentosActions.DELETE_START,
    payload: data
  }),
  deleteOrcamentoSuccess: data => ({
    type: orcamentosActions.DELETE_SUCCESS,
    payload: data,
  }),
  deleteOrcamentoFailure: error => ({
    type: orcamentosActions.DELETE_FAILURE,
    payload: error,
  }),
};

export default orcamentosActions;
