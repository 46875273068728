const userActions = {
  FETCH_USER_DATA_START: 'FETCH_USER_DATA_START',
  FETCH_USER_DATA_SUCCESS: 'FETCH_USER_DATA_SUCCESS',
  FETCH_USER_DATA_FAILURE: 'FETCH_USER_DATA_FAILURE',
  CREATE_USER_START: 'CREATE_USER_START',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  CREATE_USER_FAILURE: 'CREATE_USER_FAILURE',
  FETCH_ALL_USERS_DATA_START: 'FETCH_ALL_USERS_DATA_START',
  FETCH_ALL_USERS_DATA_SUCCESS: 'FETCH_ALL_USERS_DATA_SUCCESS',
  FETCH_ALL_USERS_DATA_FAILURE: 'FETCH_ALL_USERS_DATA_FAILURE',
  UPDATE_USER_DATA_REQUEST: 'UPDATE_USER_DATA_REQUEST',
  UPDATE_USER_DATA_SUCCESS: 'UPDATE_USER_DATA_SUCCESS',
  UPDATE_USER_DATA_FAILURE: 'UPDATE_USER_DATA_FAILURE',

  createUserStart: user => ({
    type: userActions.CREATE_USER_START,
    payload: user,
  }),
  fetchAllUsersDataStart: () => ({
    type: userActions.FETCH_ALL_USERS_DATA_START,
  }),
  fetchAllUsersDataSuccess: users => ({
    type: userActions.FETCH_ALL_USERS_DATA_SUCCESS,
    payload: users,
  }),
  fetchAllUsersDataFailure: error => ({
    type: userActions.FETCH_ALL_USERS_DATA_FAILURE,
    payload: error,
  }),
  fetchUserDataStart: id => ({
    type: userActions.FETCH_USER_DATA_START,
    payload: id,
  }),
  fetchUserDataSuccess: user => ({
    type: userActions.FETCH_USER_DATA_SUCCESS,
    payload: user,
  }),
  fetchUserDataFailure: error => ({
    type: userActions.FETCH_USER_DATA_FAILURE,
    payload: error,
  }),
  updateUser: user => ({
    type: userActions.UPDATE_USER_DATA_REQUEST,
    payload: user,
  }),
  updateUserFailure: error => ({
    type: userActions.UPDATE_USER_DATA_FAILURE,
    payload: error,
  }),
};

export default userActions;
