import actions from './actions';

const INITIAL_DATA = {
  data: null,
  dataConvites: null,
  loading: true,
  error: null,
  
  novaPesquisa: {
    estruturaOrganizacional: [],
    pagina1: {},
    pagina2: {},
    pagina3: {},
    pagina4: {},
    pagina5: {},
    pagina6: {},
    pagina7: {},
    pagina8: {},
    pagina9: {},
    pagina10: {
      listaCargos: [],
      numeroCargos: []
    },
  },
  novaPesquisaSuccess: null,
  novaPesquisaLoading: null,
  novaPesquisaError: null,

  questionariosBeneficios: null,
  questionariosBeneficiosLoading: null,
  questionariosBeneficiosError: null,

  questionarioBeneficiosData: {
    estruturaOrganizacional: [],
    pagina1: {},
    pagina2: {},
    pagina3: {},
    pagina4: {},
    pagina5: {},
    pagina6: {},
    pagina7: {},
    pagina8: {},
  },
  questionarioBeneficiosDataLoading: null,
  questionarioBeneficiosDataError: null,
  questionarioBeneficiosDataSuccess: null,

  deleteSuccess: null,
  deleteLoading: null,
  deleteError: null,

  createConviteSuccess: null,
  createConviteLoading: null,
  createConviteError: null,

  pesquisaToAnswer: null,
  pesquisaToAnswerLoading: null,
  pesquisaToAnswerError: null,
};
export default function pesquisaReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case actions.CLEAR_PESQUISA:
      return {
        ...state,
        novaPesquisa: INITIAL_DATA.novaPesquisa
      };
    case actions.UPDATE_NOVA_PESQUISA:
      return {
        ...state,
        novaPesquisa: action.payload
      };
    case actions.CREATE_SUCCESS:
      return {
        ...state,
        novaPesquisaSuccess: action.payload,
        novaPesquisaLoading: null,
        novaPesquisaError: null,
      };
    case actions.CREATE_FAILURE:
      return {
        ...state,
        novaPesquisaSuccess: null,
        novaPesquisaLoading: null,
        novaPesquisaError: true,
      };

    case actions.UPDATE_SUCCESS:
      let index = state.data.pesquisas.findIndex(x => x.id === action.payload.id);
      state.data.pesquisas[index] = action.payload;
      return {
        ...state,
        data: { pesquisas: [action.payload, ...state.data.pesquisas] },
        loading: false,
        error: null,
      };
    case actions.UPDATE_FAILURE:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload,
      };
    case actions.FETCH_ALL_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case actions.FETCH_ALL_FAILURE:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload,
      };
    case actions.FETCH_ALL_CONVITES_SUCCESS:
      return {
        ...state,
        dataConvites: action.payload,
        loading: false,
        error: null,
      };
    case actions.FETCH_ALL_CONVITES_FAILURE:
      return {
        ...state,
        dataConvites: null,
        loading: false,
        error: action.payload,
      };
    case actions.DELETE_REQUEST:
      return {
        ...state,
        deleteData: null,
        deleteLoading: true,
        deleteError: null,
      };
    case actions.DELETE_SUCCESS:
      return {
        ...state,
        deleteData: action.payload,
        deleteLoading: false,
        deleteError: null,
      };
    case actions.DELETE_FAILURE:
      return {
        ...state,
        deleteData: null,
        deleteLoading: false,
        deleteError: action.payload,
      };
    case actions.CREATE_CONVITE_REQUEST:
      return {
        ...state,
        createConviteData: null,
        createConviteLoading: true,
        createConviteError: null,
      };
    case actions.CREATE_CONVITE_SUCCESS:
      return {
        ...state,
        createConviteData: action.payload,
        createConviteLoading: false,
        createConviteError: null,
      };
    case actions.CREATE_CONVITE_FAILURE:
      return {
        ...state,
        createConviteData: null,
        createConviteLoading: false,
        createConviteError: action.payload,
      };
    case actions.SET_PESQUISA_TO_ANSWER:
      return {
        ...state,
        pesquisaToAnswer: null,
        pesquisaToAnswerLoading: false,
        pesquisaToAnswerError: null,
      };
    case actions.FETCH_BY_ID_REQUEST:
      return {
        ...state,
        pesquisaToAnswer: null,
        pesquisaToAnswerLoading: true,
        pesquisaToAnswerError: null,
      };
    case actions.FETCH_BY_ID_SUCCESS:
      return {
        ...state,
        pesquisaToAnswer: action.payload,
        pesquisaToAnswerLoading: false,
        pesquisaToAnswerError: null,
      };
    case actions.FETCH_BY_ID_FAILURE:
      return {
        ...state,
        pesquisaToAnswer: null,
        pesquisaToAnswerLoading: false,
        pesquisaToAnswerError: action.payload,
      };
    case actions.FETCH_RESPOSTA_BY_ID_REQUEST:
      return {
        ...state,
        // novaPesquisa: INITIAL_DATA.novaPesquisa,
        novaPesquisaLoading: true,
        novaPesquisaError: null,
      };
    case actions.FETCH_RESPOSTA_BY_ID_SUCCESS:
      return {
        ...state,
        novaPesquisa: action.payload,
        novaPesquisaLoading: false,
        novaPesquisaError: null,
      };
    case actions.FETCH_RESPOSTA_BY_ID_FAILURE:
      return {
        ...state,
        // novaPesquisa: INITIAL_DATA.novaPesquisa,
        novaPesquisaLoading: false,
        // novaPesquisaError: action.payload,
      };
      
    case actions.UPDATE_QUESTIONARIO_BENEFICIOS:
      return {
        ...state,
        questionarioBeneficiosData: action.payload,
      };
    case actions.RESET_QUESTIONARIO_BENEFICIOS:
      return {
        ...state,
        questionarioBeneficiosData: null,
        questionarioBeneficiosDataLoading: false,
        questionarioBeneficiosDataError: null,
        questionarioBeneficiosDataSuccess: null,
      };
    case actions.SET_QUESTIONARIO_BENEFICIOS:
      return {
        ...state,
        questionarioBeneficiosData: null,
        questionarioBeneficiosDataLoading: true,
        questionarioBeneficiosDataError: null,
        questionarioBeneficiosDataSuccess: null,
      };
    case actions.SET_QUESTIONARIO_BENEFICIOS_SUCCESS:
      return {
        ...state,
        questionarioBeneficiosDataLoading: false,
        questionarioBeneficiosDataError: null,
        questionarioBeneficiosDataSuccess: true,
      };
    case actions.SET_QUESTIONARIO_BENEFICIOS_ERROR:
      return {
        ...state,
        questionarioBeneficiosData: null,
        questionarioBeneficiosDataLoading: false,
        questionarioBeneficiosDataError: true,
        questionarioBeneficiosDataSuccess: false,
      };

    case actions.GET_QUESTIONARIO_BENEFICIOS:
      return {
        ...state,
        questionariosBeneficios: null,
        questionariosBeneficiosLoading: true,
        questionariosBeneficiosError: null,
      };
    case actions.GET_QUESTIONARIO_BENEFICIOS_SUCCESS:
      return {
        ...state,
        questionariosBeneficios: action.payload,
        questionariosBeneficiosLoading: false,
        questionariosBeneficiosError: null,
      };
    case actions.GET_QUESTIONARIO_BENEFICIOS_ERROR:
      return {
        ...state,
        questionariosBeneficios: null,
        questionariosBeneficiosLoading: false,
        questionariosBeneficiosError: true,
      };

    case actions.GET_QUESTIONARIO_BENEFICIOS_BY_ID:
      return {
        ...state,
        questionarioBeneficiosData: null,
        questionarioBeneficiosDataLoading: true,
        questionarioBeneficiosDataError: null,
      };
    case actions.GET_QUESTIONARIO_BENEFICIOS_BY_ID_SUCCESS:
      return {
        ...state,
        questionarioBeneficiosData: action.payload,
        questionarioBeneficiosDataLoading: false,
        questionarioBeneficiosDataError: null,
      };
    case actions.GET_QUESTIONARIO_BENEFICIOS_BY_ID_ERROR:
      return {
        ...state,
        questionarioBeneficiosData: null,
        questionarioBeneficiosDataLoading: false,
        questionarioBeneficiosDataError: true,
      };
    default:
      return state;
  }
}
