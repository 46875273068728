import userActions from './actions';

const INITIAL_DATA = {
  data: null,
  loadingData: true,
  error: null,
};
export default function profileReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case userActions.CREATE_USER_SUCCESS:
      return {
        ...state,
        data: { users: [action.payload, ...state.data.users] },
        loadingData: false,
        error: null,
      };
    case userActions.CREATE_USER_FAILURE:
      return {
        ...state,
        data: null,
        loadingData: false,
        error: action.payload,
      };
    case userActions.FETCH_ALL_USERS_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loadingData: false,
        error: null,
      };
    case userActions.FETCH_USER_DATA_FAILURE:
      return {
        ...state,
        data: action.payload,
        loadingData: false,
        error: action.error,
      };
    default:
      return state;
  }
}
