import { combineReducers } from 'redux';
import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/_app/auth/reducer';
import profile from '@iso/redux/_app/profile/reducer';
import user from '@iso/redux/_app/admin/user/reducer';
import cargos from '@iso/redux/_app/admin/cargos/reducer';
import grupos from '@iso/redux/_app/admin/grupos/reducer';
import empresa from '@iso/redux/_app/admin/empresa/reducer';
import empresaPatrocinadora from '@iso/redux/_app/pesquisa/params/empresaPatrocinadora/reducer';
import pesquisa from '@iso/redux/_app/pesquisa/params/pesquisa/reducer';
import setor from '@iso/redux/_app/admin/empresa/params/setor/reducer';
import ramoAtividade from '@iso/redux/_app/admin/empresa/params/ramoAtividade/reducer';
import orcamentos from '@iso/redux/_app/admin/orcamentos/reducer';
import Mails from '@iso/redux/mail/reducer';
import Box from '@iso/redux/box/reducer';
import Notes from '@iso/redux/notes/reducer';
import Todos from '@iso/redux/todos/reducer';
import Contacts from '@iso/redux/contacts/reducer';
import Cards from '@iso/redux/card/reducer';
import DynamicChartComponent from '@iso/redux/dynamicEchart/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import drawer from '@iso/redux/drawer/reducer';
import modal from '@iso/redux/modal/reducer';

export default combineReducers({
  Auth,
  user,
  cargos,
  grupos,
  empresa,
  setor,
  ramoAtividade,
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  Mails,
  Box,
  Notes,
  Todos,
  Contacts,
  Cards,
  DynamicChartComponent,
  modal,
  drawer,
  profile,
  pesquisa,
  empresaPatrocinadora,
  orcamentos
});
