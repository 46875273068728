const empresaPatrocinadoraActions = {
  FETCH_EMPRESA_PATROCINADORA_DATA_START: 'FETCH_EMPRESA_PATROCINADORA_DATA_START',
  FETCH_EMPRESA_PATROCINADORA_DATA_SUCCESS: 'FETCH_EMPRESA_PATROCINADORA_DATA_SUCCESS',
  FETCH_EMPRESA_PATROCINADORA_DATA_FAILURE: 'FETCH_EMPRESA_PATROCINADORA_DATA_FAILURE',

  fetchEmpresaPatrocinadoraDataStart: id => ({
    type: empresaPatrocinadoraActions.FETCH_EMPRESA_PATROCINADORA_DATA_START,
    payload: id,
  }),
  fetchEmpresaPatrocinadoraDataSuccess: empresaPatrocinadora => ({
    type: empresaPatrocinadoraActions.FETCH_EMPRESA_PATROCINADORA_DATA_SUCCESS,
    payload: empresaPatrocinadora,
  }),
  fetchEmpresaPatrocinadoraDataFailure: error => ({
    type: empresaPatrocinadoraActions.FETCH_EMPRESA_PATROCINADORA_DATA_FAILURE,
    payload: error,
  }),
};

export default empresaPatrocinadoraActions;
