import empresaPatrocinadoraActions from './actions';

const INITIAL_DATA = {
  data: null,
  loadingData: true,
  error: null,
};
export default function empresaPatrocinadoraReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case empresaPatrocinadoraActions.FETCH_EMPRESA_PATROCINADORA_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loadingData: false,
        error: null,
      };
    case empresaPatrocinadoraActions.FETCH_EMPRESA_PATROCINADORA_DATA_FAILURE:
      return {
        ...state,
        data: null,
        loadingData: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
