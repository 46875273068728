import actions from './actions';

const initState = {
  idToken: null,
  error: null
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        idToken: action.token,
        error: null
      };
    case actions.LOGIN_ERROR:
      return {
        idToken: null,
        error: action.error
      };
    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}
