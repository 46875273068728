import cargosActions from './actions';

const INITIAL_DATA = {
  data: null,
  loadingData: true,
  error: null,

  deletedata: null,
  deleteloading: false,
  deleteerror: null,

  uploadCSVdata: null,
  uploadCSVloading: false,
  uploadCSVerror: null,
};
export default function profileReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case cargosActions.CREATE_CARGOS_SUCCESS:
      return {
        ...state,
        data: { cargos: [action.payload, ...state.data.cargos] },
        loadingData: false,
        error: null,
      };
    case cargosActions.CREATE_CARGOS_FAILURE:
      return {
        ...state,
        data: null,
        loadingData: false,
        error: action.payload,
      };
    case cargosActions.FETCH_ALL_CARGOS_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loadingData: false,
        error: null,
      };
    case cargosActions.FETCH_ALL_CARGOS_DATA_FAILURE:
      return {
        ...state,
        data: action.payload,
        loadingData: false,
        error: action.error,
      };
    case cargosActions.DELETE_CARGOS_SUCCESS:
      return {
        ...state,
        deletedata: action.payload,
        deleteloading: false,
        deleteerror: null,
      };
    case cargosActions.DELETE_CARGOS_FAILURE:
      return {
        ...state,
        deletedata: null,
        deleteloading: false,
        deleteerror: action.payload,
      };
    case cargosActions.UPLOAD_CSV_REQUEST:
      return {
        ...state,
        uploadCSVdata: null,
        uploadCSVloading: true,
        uploadCSVerror: null,
      };
    case cargosActions.UPLOAD_CSV_SUCCESS:
      return {
        ...state,
        uploadCSVdata: action.payload,
        uploadCSVloading: false,
        uploadCSVerror: null,
      };
    case cargosActions.UPLOAD_CSV_FAILURE:
      return {
        ...state,
        uploadCSVdata: null,
        uploadCSVloading: false,
        uploadCSVerror: action.payload,
      };
    default:
      return state;
  }
}
