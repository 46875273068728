// saga.js
import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import axios from '@iso/config/axios.config';

function* createRequest({ payload }) {
  try {
    let { data } = yield call(axios.post, 'api/v1/admin/empresa/params/setor/create', payload);
    yield put({
      type: actions.CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: actions.CREATE_FAILURE,
      payload: error.response.data,
    });
  }
}

function* updateRequest({ payload }) {
  try {
    let { data } = yield call(axios.put, 'api/v1/admin/empresa/params/setor/update', payload);
    yield put({
      type: actions.UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: actions.UPDATE_FAILURE,
      payload: error.response.data,
    });
  }
}

function* deleteRequest({ payload }) {
  try {
    let { data } = yield call(axios.delete, `api/v1/admin/empresa/params/setor/delete/${payload}`);
    yield put({
      type: actions.DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: actions.DELETE_FAILURE,
      payload: error.response.data,
    });
  }
}

function* fetchAllRequest() {
  try {
    let { data } = yield call(axios.get, 'api/v1/admin/empresa/params/setor/fetchAll')
    yield put({
      type: actions.FETCH_ALL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_ALL_FAILURE,
      payload: error.response.data,
    });
  }
}

export default function* profileSaga() {
  yield all([
    takeEvery(actions.CREATE_REQUEST, createRequest),
    takeEvery(actions.UPDATE_REQUEST, updateRequest),
    takeEvery(actions.DELETE_REQUEST, deleteRequest),
    takeEvery(actions.FETCH_ALL_REQUEST, fetchAllRequest),
  ]);
}
