// saga.js
import { all, takeEvery, put, call } from 'redux-saga/effects';
import orcamentosActions from './actions';
import axios from '../../../../config/axios.config';

function* fetchAllOrcamentosRequest() {
  try {
    let { data } = yield call(axios.get, 'api/v1/orcamento/fetchAll')
    yield put({
      type: orcamentosActions.FETCH_ALL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: orcamentosActions.FETCH_ALL_FAILURE,
      payload: error.response.data,
    });
  }
}

function* deleteOrcamentoRequest({ payload }) {
  try {
    let { data } = yield call(axios.delete, 'api/v1/orcamento/delete/' + payload)
    yield put({
      type: orcamentosActions.DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: orcamentosActions.DELETE_FAILURE,
      payload: error.response.data,
    });
  }
}

export default function* orcamentosSaga() {
  yield all([
    takeEvery(orcamentosActions.FETCH_ALL_START, fetchAllOrcamentosRequest),
    takeEvery(orcamentosActions.DELETE_START, deleteOrcamentoRequest),
  ]);
}
