// saga.js
import { all, takeEvery, put, call } from 'redux-saga/effects';
import profileActions from './actions';
import axios from '../../../config/axios.config';
import authAction from '@iso/redux/auth/actions';
import { getToken, clearToken } from '@iso/lib/helpers/utility';

function* fetchProfileDataEffect() {
  try {
    let { data } = yield call(axios.get, 'api/v1/user/fetchProfile')
    yield put(profileActions.fetchProfileDataSuccess(data));
  } catch (error) {
    yield put(authAction.logout());
    // yield put(profileActions.fetchProfileDataFailure(error.response.data));
  }
}

function* updateProfileDataRequest({ payload }) {
  try {
    let { data } = yield call(axios.put, 'api/v1/user/update', payload);
    yield put({
      type: profileActions.UPDATE_PROFILE_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: profileActions.UPDATE_PROFILE_DATA_FAILURE,
      payload: error.response.data,
    });
  }
}

export default function* profileSaga() {
  yield all([
    takeEvery(profileActions.FETCH_PROFILE_DATA_START, fetchProfileDataEffect),
    takeEvery(profileActions.UPDATE_PROFILE_DATA_REQUEST, updateProfileDataRequest),
  ]);
}
