const v = '_PESQUISA'
const pesquisaActions = {
  CREATE_REQUEST: 'CREATE_REQUEST' + v,
  CREATE_SUCCESS: 'CREATE_SUCCESS' + v,
  CREATE_FAILURE: 'CREATE_FAILURE' + v,
  FETCH_REQUEST: 'FETCH_REQUEST' + v,
  FETCH_SUCCESS: 'FETCH_SUCCESS' + v,
  FETCH_FAILURE: 'FETCH_FAILURE' + v,

  FETCH_ALL_REQUEST: 'FETCH_ALL_REQUEST' + v,
  FETCH_ALL_SUCCESS: 'FETCH_ALL_SUCCESS' + v,
  FETCH_ALL_FAILURE: 'FETCH_ALL_FAILURE' + v,

  FETCH_ALL_CONVITES_REQUEST: 'FETCH_ALL_CONVITES_REQUEST' + v,
  FETCH_ALL_CONVITES_SUCCESS: 'FETCH_ALL_CONVITES_SUCCESS' + v,
  FETCH_ALL_CONVITES_FAILURE: 'FETCH_ALL_CONVITES_FAILURE' + v,

  UPDATE_REQUEST: 'UPDATE_REQUEST' + v,
  UPDATE_SUCCESS: 'UPDATE_SUCCESS' + v,
  UPDATE_FAILURE: 'UPDATE_FAILURE' + v,

  DELETE_REQUEST: 'DELETE_REQUEST',
  DELETE_SUCCESS: 'DELETE_SUCCESS',
  DELETE_FAILURE: 'DELETE_FAILURE',

  CREATE_CONVITE_REQUEST: 'CREATE_CONVITE_REQUEST',
  CREATE_CONVITE_SUCCESS: 'CREATE_CONVITE_SUCCESS',
  CREATE_CONVITE_FAILURE: 'CREATE_CONVITE_FAILURE',

  DELETE_CONVITE_REQUEST: 'DELETE_CONVITE_REQUEST',
  DELETE_CONVITE_SUCCESS: 'DELETE_CONVITE_SUCCESS',
  DELETE_CONVITE_FAILURE: 'DELETE_CONVITE_FAILURE',

  FETCH_BY_ID_REQUEST: 'FETCH_BY_ID_REQUEST',
  FETCH_BY_ID_SUCCESS: 'FETCH_BY_ID_SUCCESS',
  FETCH_BY_ID_FAILURE: 'FETCH_BY_ID_FAILURE',

  FETCH_RESPOSTA_BY_ID_REQUEST: 'FETCH_RESPOSTA_BY_ID_REQUEST',
  FETCH_RESPOSTA_BY_ID_SUCCESS: 'FETCH_RESPOSTA_BY_ID_SUCCESS',
  FETCH_RESPOSTA_BY_ID_FAILURE: 'FETCH_RESPOSTA_BY_ID_FAILURE',
  SET_PESQUISA_TO_ANSWER: 'SET_PESQUISA_TO_ANSWER',

  CLEAR_PESQUISA: 'CLEAR_PESQUISA',
  UPDATE_NOVA_PESQUISA: 'UPDATE_NOVA_PESQUISA',
  CREATE_RESPOSTA_REQUEST: 'CREATE_RESPOSTA_REQUEST',

  UPDATE_QUESTIONARIO_BENEFICIOS: 'UPDATE_QUESTIONARIO_BENEFICIOS',
  RESET_QUESTIONARIO_BENEFICIOS: 'RESET_QUESTIONARIO_BENEFICIOS',
  SET_QUESTIONARIO_BENEFICIOS: 'SET_QUESTIONARIO_BENEFICIOS',
  SET_QUESTIONARIO_BENEFICIOS_SUCCESS: 'SET_QUESTIONARIO_BENEFICIOS_SUCCESS',
  SET_QUESTIONARIO_BENEFICIOS_ERROR: 'SET_QUESTIONARIO_BENEFICIOS_ERROR',

  GET_QUESTIONARIO_BENEFICIOS_BY_ID: 'GET_QUESTIONARIO_BENEFICIOS_BY_ID',
  GET_QUESTIONARIO_BENEFICIOS_BY_ID_SUCCESS: 'GET_QUESTIONARIO_BENEFICIOS_BY_ID_SUCCESS',
  GET_QUESTIONARIO_BENEFICIOS_BY_ID_ERROR: 'GET_QUESTIONARIO_BENEFICIOS_BY_ID_ERROR',

  GET_QUESTIONARIO_BENEFICIOS: 'GET_QUESTIONARIO_BENEFICIOS',
  GET_QUESTIONARIO_BENEFICIOS_SUCCESS: 'GET_QUESTIONARIO_BENEFICIOS_SUCCESS',
  GET_QUESTIONARIO_BENEFICIOS_ERROR: 'GET_QUESTIONARIO_BENEFICIOS_ERROR',

  fetchQuestionarioBeneficioByIdRequest: data => ({
    type: pesquisaActions.GET_QUESTIONARIO_BENEFICIOS_BY_ID,
    payload: data
  }),

  fetchQuestionariosBeneficiosRequest: data => ({
    type: pesquisaActions.GET_QUESTIONARIO_BENEFICIOS,
    payload: data
  }),
  fetchQuestionariosBeneficiosSuccess: data => ({
    type: pesquisaActions.GET_QUESTIONARIO_BENEFICIOS_SUCCESS,
    payload: data,
  }),
  fetchQuestionariosBeneficiosFailure: error => ({
    type: pesquisaActions.GET_QUESTIONARIO_BENEFICIOS_ERROR,
    payload: error,
  }),

  resetQuestionarioBeneficiosRequest: data => ({
    type: pesquisaActions.RESET_QUESTIONARIO_BENEFICIOS,
    payload: data
  }),

  setQuestionarioBeneficiosRequest: data => ({
    type: pesquisaActions.SET_QUESTIONARIO_BENEFICIOS,
    payload: data
  }),
  setQuestionarioBeneficiosSuccess: data => ({
    type: pesquisaActions.SET_QUESTIONARIO_BENEFICIOS_SUCCESS,
    payload: data,
  }),
  setQuestionarioBeneficiosFailure: error => ({
    type: pesquisaActions.SET_QUESTIONARIO_BENEFICIOS_ERROR,
    payload: error,
  }),

  updateQuestionarioBeneficios: data => ({
    type: pesquisaActions.UPDATE_QUESTIONARIO_BENEFICIOS,
    payload: data,
  }),

  updateNovaPesquisa: data => ({
    type: pesquisaActions.UPDATE_NOVA_PESQUISA,
    payload: data,
  }),

  updatePesquisa: data => ({
    type: pesquisaActions.UPDATE_REQUEST,
    payload: data,
  }),

  createNovaResposta: data => ({
    type: pesquisaActions.CREATE_RESPOSTA_REQUEST,
    payload: data,
  }),
  createNovaPesquisa: data => ({
    type: pesquisaActions.CREATE_REQUEST,
    payload: data,
  }),
  fetchAllConvitesRequest: data => ({
    type: pesquisaActions.FETCH_ALL_CONVITES_REQUEST,
    payload: data
  }),
  fetchAllConvitesSuccess: data => ({
    type: pesquisaActions.FETCH_ALL_CONVITES_SUCCESS,
    payload: data,
  }),
  fetchAllConvitesFailure: error => ({
    type: pesquisaActions.FETCH_ALL_CONVITES_FAILURE,
    payload: error,
  }),
  fetchAllRequest: data => ({
    type: pesquisaActions.FETCH_ALL_REQUEST,
    payload: data
  }),
  fetchAllSuccess: data => ({
    type: pesquisaActions.FETCH_ALL_SUCCESS,
    payload: data,
  }),
  fetchAllFailure: error => ({
    type: pesquisaActions.FETCH_ALL_FAILURE,
    payload: error,
  }),
  fetchRequest: id => ({
    type: pesquisaActions.FETCH_REQUEST,
    payload: id,
  }),
  fetchSuccess: data => ({
    type: pesquisaActions.FETCH_SUCCESS,
    payload: data,
  }),
  fetchFailure: error => ({
    type: pesquisaActions.FETCH_FAILURE,
    payload: error,
  }),
  updateRequest: data => ({
    type: pesquisaActions.UPDATE_REQUEST,
    payload: data,
  }),
  updateFailure: error => ({
    type: pesquisaActions.UPDATE_FAILURE,
    payload: error,
  }),
  deleteRequest: data => ({
    type: pesquisaActions.DELETE_REQUEST,
    payload: data,
  }),
  deleteFailure: error => ({
    type: pesquisaActions.DELETE_FAILURE,
    payload: error,
  }),
  createConviteRequest: data => ({
    type: pesquisaActions.CREATE_CONVITE_REQUEST,
    payload: data,
  }),
  deleteConviteRequest: data => ({
    type: pesquisaActions.DELETE_CONVITE_REQUEST,
    payload: data,
  }),
  createConviteFailure: error => ({
    type: pesquisaActions.CREATE_CONVITE_FAILURE,
    payload: error,
  }),
  setPesquisaToAnswer: data => ({
    type: pesquisaActions.SET_PESQUISA_TO_ANSWER,
    payload: data,
  }),
  fetchById: data => ({
    type: pesquisaActions.FETCH_BY_ID_REQUEST,
    payload: data,
  }),
  clearPesquisa: data => ({
    type: pesquisaActions.CLEAR_PESQUISA,
    payload: data,
  }),
  fetchByIdFailure: error => ({
    type: pesquisaActions.FETCH_BY_ID_FAILURE,
    payload: error,
  }),
  fetchRespostaById: data => ({
    type: pesquisaActions.FETCH_RESPOSTA_BY_ID_REQUEST,
    payload: data,
  }),
  fetchRespostaByIdFailure: error => ({
    type: pesquisaActions.FETCH_RESPOSTA_BY_ID_FAILURE,
    payload: error,
  }),
};

export default pesquisaActions;
