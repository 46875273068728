// saga.js
import { all, takeEvery, put, call } from 'redux-saga/effects';
import userActions from './actions';
import axios from '../../../../config/axios.config';

function* createUserStart({ payload }) {
  try {
    let { data } = yield call(axios.post, 'api/v1/user/create', payload);
    yield put({
      type: userActions.CREATE_USER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: userActions.CREATE_USER_FAILURE,
      payload: error.response.data,
    });
  }
}

function* fetchAllUsersDataRequest() {
  try {
    let { data } = yield call(axios.get, 'api/v1/user/fetchAll')
    yield put({
      type: userActions.FETCH_ALL_USERS_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: userActions.FETCH_ALL_USERS_DATA_FAILURE,
      payload: error.response.data,
    });
  }
}

// function* updateProfileDataRequest({ payload }) {
//   try {
//     const form = new FormData();
//     const config = { headers: { 'Content-Type': 'multipart/form-data' } };
//     form.append('profileImage', payload.profileImage);
//     form.append('profileData', JSON.stringify(payload.profileData));
//     let { data } = yield call(axios.put, 'api/v1/user/updateUser', form, config);
//     yield put({
//       type: userActions.UPDATE_USER_DATA_SUCCESS,
//       payload: data,
//     });
//   } catch (error) {
//     yield put({
//       type: userActions.UPDATE_USER_DATA_FAILURE,
//       payload: error.response.data,
//     });
//   }
// }

function* updateProfileDataRequest({ payload }) {
  try {
    let { data } = yield call(axios.put, 'api/v1/user/update', payload);
    yield put({
      type: userActions.UPDATE_USER_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: userActions.UPDATE_USER_DATA_FAILURE,
      payload: error.response.data,
    });
  }
}

export default function* profileSaga() {
  yield all([
    takeEvery(userActions.FETCH_ALL_USERS_DATA_START, fetchAllUsersDataRequest),
    takeEvery(userActions.CREATE_USER_START, createUserStart),
    takeEvery(userActions.UPDATE_USER_DATA_REQUEST, updateProfileDataRequest),
  ]);
}
