import React from 'react';
import { Provider } from 'react-redux';
import GlobalStyles from '@iso/assets/styles/globalStyle';
import { store } from './redux/store';
import Boot from './redux/boot';
import Routes from './router';
import AppProvider from './AppProvider';
import axios from './config/axios.config';
import { clearToken } from '@iso/lib/helpers/utility';

axios.interceptors.request.use(config => {

  if (
    !config.url.endsWith('signin') ||
    !config.url.endsWith('signup') ||
    !config.url.endsWith('addUser')
  ) {
    config.headers.Authorization = localStorage.getItem('id_token');
    return config;
  }
}, (error) => {
  // I can handle a request with errors here
  return Promise.reject(error);
});

axios.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401) {
    clearToken();
    if(!window.location.href === "http://localhost:3000/signin"){
      window.location.href = "http://localhost:3000/signin";
    }
  }
  return Promise.reject(error);
  
});

const App = () => (
  <Provider store={store}>
    <AppProvider>
      <>
        <GlobalStyles />
        <Routes />
      </>
    </AppProvider>
  </Provider>
);
Boot()
  .then(() => App())
  .catch(error => console.error(error));

export default App;
