// saga.js
import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import axios from '@iso/config/axios.config';
import moment from 'moment';

function* createConviteRequest({ payload }) {
  try {
    let { data } = yield call(axios.post, 'api/v1/pesquisa/convite/create', payload);
    yield put({
      type: actions.CREATE_CONVITE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: actions.CREATE_CONVITE_FAILURE,
      payload: true,
    });
  }
}

function* getQuestionariosBeneficiosRequest({ payload }) {
  try {
    let { data } = yield call(axios.get, 'api/v1/pesquisa/beneficios/fetchByEmpresa/' + payload);
    data.formsPesquisa.sort(function(a, b){
      return moment(b.createdAt).isAfter(moment(a.createdAt)) ? 1 : -1
    });
    yield put({
      type: actions.GET_QUESTIONARIO_BENEFICIOS_SUCCESS,
      payload: data.formsPesquisa ? data.formsPesquisa : {},
    });
  } catch (error) {
    yield put({
      type: actions.GET_QUESTIONARIO_BENEFICIOS_FAILURE,
      payload: true,
    });
  }
}

function* getQuestionarioBeneficiosByIdRequest({ payload }) {
  try {
    let { data } = yield call(axios.get, 'api/v1/pesquisa/beneficios/findById/' + payload);
    yield put({
      type: actions.GET_QUESTIONARIO_BENEFICIOS_BY_ID_SUCCESS,
      payload: data.form ? JSON.parse(data.form) : {},
    });
  } catch (error) {
    yield put({
      type: actions.GET_QUESTIONARIO_BENEFICIOS_BY_ID_FAILURE,
      payload: true,
    });
  }
}

function* setQuestionarioBeneficiosRequest({ payload }) {
  try {
    let url = payload.id ? 'api/v1/pesquisa/beneficios/update' : 'api/v1/pesquisa/beneficios/create'
    let { data } = payload.id ? yield call(axios.put, url, payload) : yield call(axios.post, url, payload);
    yield put({
      type: actions.SET_QUESTIONARIO_BENEFICIOS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: actions.SET_QUESTIONARIO_BENEFICIOS_FAILURE,
      payload: true,
    });
  }
}

function* deleteConviteRequest({ payload }) {
  try {
    let { data } = yield call(axios.delete, 'api/v1/pesquisa/convite/delete/' + payload);
    yield put({
      type: actions.DELETE_CONVITE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: actions.DELETE_CONVITE_FAILURE,
      payload: true,
    });
  }
}

function* createRespostaRequest({ payload }) {
  try {
    let { data } = yield call(axios.put, 'api/v1/pesquisa/convite/update', payload);
    yield put({
      type: actions.CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: actions.CREATE_FAILURE,
      payload: error.response.data,
    });
  }
}

function* createRequest({ payload }) {
  try {
    let { data } = yield call(axios.post, 'api/v1/pesquisa/PesquisaForm/create', payload);
    yield put({
      type: actions.CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: actions.CREATE_FAILURE,
      payload: error.response.data,
    });
  }
}

function* updateRequest({ payload }) {
  try {
    let { data } = yield call(axios.put, 'api/v1/pesquisa/PesquisaForm/update', payload);
    yield put({
      type: actions.UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: actions.UPDATE_FAILURE,
      payload: true,
    });
  }
}

function* deleteRequest({ payload }) {
  try {
    let { data } = yield call(axios.delete, `api/v1/pesquisa/PesquisaForm/delete/${payload}`);
    yield put({
      type: actions.DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: actions.DELETE_FAILURE,
      payload: error.response.data,
    });
  }
}

function* getById({ payload }) {
  try {
    let { data } = yield call(axios.get, `api/v1/pesquisa/PesquisaForm/findById/${payload}`);
    yield put({
      type: actions.FETCH_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_BY_ID_FAILURE,
      payload: error.response.data,
    });
  }
}

function* getRespostaById({ payload }) {
  try {
    let { data } = yield call(axios.get, `/api/v1/pesquisa/PesquisaForm/answer/findById/${payload}`);
    yield put({
      type: actions.FETCH_RESPOSTA_BY_ID_SUCCESS,
      payload: JSON.parse(data.resposta),
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_RESPOSTA_BY_ID_FAILURE,
      payload: error.response.data,
    });
  }
}

function* fetchAllRequest({ payload }) {
  try {
    let { data } = yield call(axios.get, 'api/v1/pesquisa/PesquisaForm/fetchAll');
    let aux = { formsPesquisa: [] };
    data.formsPesquisa.forEach((item) => {
      if (payload) {
        if (item.empresa.id === payload) {
          aux.formsPesquisa.push(item)
        }
      } else {
        aux.formsPesquisa.push(item)
      }
    })
    yield put({
      type: actions.FETCH_ALL_SUCCESS,
      payload: aux,
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_ALL_FAILURE,
      payload: error.response.data,
    });
  }
}

function* fetchAllConvitesRequest({ payload }) {
  try {
    let { data } = yield call(axios.get, `api/v1/pesquisa/convite/fetchByEmpresaParticipante/${payload}`)
    yield put({
      type: actions.FETCH_ALL_CONVITES_SUCCESS,
      payload: { formsPesquisa: data.convites },
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_ALL_CONVITES_FAILURE,
      payload: error.response.data,
    });
  }
}

export default function* profileSaga() {
  yield all([
    takeEvery(actions.CREATE_REQUEST, createRequest),
    takeEvery(actions.UPDATE_REQUEST, updateRequest),
    takeEvery(actions.FETCH_ALL_REQUEST, fetchAllRequest),
    takeEvery(actions.FETCH_ALL_CONVITES_REQUEST, fetchAllConvitesRequest),
    takeEvery(actions.DELETE_REQUEST, deleteRequest),
    takeEvery(actions.CREATE_CONVITE_REQUEST, createConviteRequest),
    takeEvery(actions.DELETE_CONVITE_REQUEST, deleteConviteRequest),
    takeEvery(actions.FETCH_BY_ID_REQUEST, getById),
    takeEvery(actions.CREATE_RESPOSTA_REQUEST, createRespostaRequest),
    takeEvery(actions.FETCH_RESPOSTA_BY_ID_REQUEST, getRespostaById),

    takeEvery(actions.SET_QUESTIONARIO_BENEFICIOS, setQuestionarioBeneficiosRequest),
    takeEvery(actions.GET_QUESTIONARIO_BENEFICIOS, getQuestionariosBeneficiosRequest),
    takeEvery(actions.GET_QUESTIONARIO_BENEFICIOS_BY_ID, getQuestionarioBeneficiosByIdRequest),
  ]);
}
