const cargosActions = {
  CREATE_CARGOS_START: 'CREATE_CARGOS_START',
  CREATE_CARGOS_SUCCESS: 'CREATE_CARGOS_SUCCESS',
  CREATE_CARGOS_FAILURE: 'CREATE_CARGOS_FAILURE',
  FETCH_ALL_CARGOS_DATA_START: 'FETCH_ALL_CARGOS_DATA_START',
  FETCH_ALL_CARGOS_DATA_SUCCESS: 'FETCH_ALL_CARGOS_DATA_SUCCESS',
  FETCH_ALL_CARGOS_DATA_FAILURE: 'FETCH_ALL_CARGOS_DATA_FAILURE',
  UPDATE_CARGOS_DATA_REQUEST: 'UPDATE_CARGOS_DATA_REQUEST',
  UPDATE_CARGOS_DATA_SUCCESS: 'UPDATE_CARGOS_DATA_SUCCESS',
  UPDATE_CARGOS_DATA_FAILURE: 'UPDATE_CARGOS_DATA_FAILURE',
  DELETE_CARGOS_REQUEST: 'DELETE_CARGOS_REQUEST',
  DELETE_CARGOS_SUCCESS: 'DELETE_CARGOS_SUCCESS',
  DELETE_CARGOS_FAILURE: 'DELETE_CARGOS_FAILURE',
  UPLOAD_CSV_REQUEST: 'UPLOAD_CSV_REQUEST',
  UPLOAD_CSV_SUCCESS: 'UPLOAD_CSV_SUCCESS',
  UPLOAD_CSV_FAILURE: 'UPLOAD_CSV_FAILURE',

  createCargosStart: cargos => ({
    type: cargosActions.CREATE_CARGOS_START,
    payload: cargos,
  }),
  fetchAllCargosDataStart: () => ({
    type: cargosActions.FETCH_ALL_CARGOS_DATA_START,
  }),
  fetchAllCargosDataSuccess: cargos => ({
    type: cargosActions.FETCH_ALL_CARGOS_DATA_SUCCESS,
    payload: cargos,
  }),
  fetchAllCargosDataFailure: error => ({
    type: cargosActions.FETCH_ALL_CARGOS_DATA_FAILURE,
    payload: error,
  }),
  updateCargosStart: cargos => ({
    type: cargosActions.UPDATE_CARGOS_DATA_REQUEST,
    payload: cargos,
  }),
  updateCargosFailure: error => ({
    type: cargosActions.UPDATE_CARGOS_DATA_FAILURE,
    payload: error,
  }),
  deleteCargosStart: cargos => ({
    type: cargosActions.DELETE_CARGOS_REQUEST,
    payload: cargos,
  }),
  deleteCargosSuccess: cargos => ({
    type: cargosActions.DELETE_CARGOS_SUCCESS,
    payload: cargos,
  }),
  deleteCargosFailure: error => ({
    type: cargosActions.DELETE_CARGOS_FAILURE,
    payload: error,
  }),
  uploadCSVStart: file => ({
    type: cargosActions.UPLOAD_CSV_REQUEST,
    payload: file,
  }),
  uploadCSVSuccess: data => ({
    type: cargosActions.UPLOAD_CSV_SUCCESS,
    payload: data,
  }),
  uploadCSVFailure: error => ({
    type: cargosActions.UPLOAD_CSV_FAILURE,
    payload: error,
  }),
};

export default cargosActions;
