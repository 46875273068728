// saga.js
import { all, takeEvery, put, call } from 'redux-saga/effects';
import gruposActions from './actions';
import axios from '../../../../config/axios.config';

function* createGruposStart({ payload }) {
  try {
    let { data } = yield call(axios.post, 'api/v1/admin/empresa/params/grupo/create', payload);
    yield put({
      type: gruposActions.CREATE_GRUPOS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: gruposActions.CREATE_GRUPOS_FAILURE,
      payload: error.response.data,
    });
  }
}

function* fetchAllGruposDataRequest() {
  try {
    let { data } = yield call(axios.get, 'api/v1/admin/empresa/params/grupo/fetchAll')
    yield put({
      type: gruposActions.FETCH_ALL_GRUPOS_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: gruposActions.FETCH_ALL_GRUPOS_DATA_FAILURE,
      payload: error.response.data,
    });
  }
}

function* updateProfileDataRequest({ payload }) {
  try {
    let { data } = yield call(axios.put, 'api/v1/admin/empresa/params/grupo/update', payload);
    yield put({
      type: gruposActions.UPDATE_GRUPOS_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: gruposActions.UPDATE_GRUPOS_DATA_FAILURE,
      payload: error.response.data,
    });
  }
}

function* deleteGrupoRequest({ payload }) {
  try {
    let { data } = yield call(axios.delete, `api/v1/admin/empresa/params/grupo/delete/${payload}`);
    yield put({
      type: gruposActions.DELETE_GRUPO_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: gruposActions.DELETE_GRUPO_FAILURE,
      payload: error.response.data,
    });
  }
}

export default function* profileSaga() {
  yield all([
    takeEvery(gruposActions.FETCH_ALL_GRUPOS_DATA_START, fetchAllGruposDataRequest),
    takeEvery(gruposActions.CREATE_GRUPOS_START, createGruposStart),
    takeEvery(gruposActions.UPDATE_GRUPOS_DATA_REQUEST, updateProfileDataRequest),
    takeEvery(gruposActions.DELETE_GRUPO_REQUEST, deleteGrupoRequest),
  ]);
}
