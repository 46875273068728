const gruposActions = {
  CREATE_GRUPOS_START: 'CREATE_GRUPOS_START',
  CREATE_GRUPOS_SUCCESS: 'CREATE_GRUPOS_SUCCESS',
  CREATE_GRUPOS_FAILURE: 'CREATE_GRUPOS_FAILURE',
  FETCH_ALL_GRUPOS_DATA_START: 'FETCH_ALL_GRUPOS_DATA_START',
  FETCH_ALL_GRUPOS_DATA_SUCCESS: 'FETCH_ALL_GRUPOS_DATA_SUCCESS',
  FETCH_ALL_GRUPOS_DATA_FAILURE: 'FETCH_ALL_GRUPOS_DATA_FAILURE',
  UPDATE_GRUPOS_DATA_REQUEST: 'UPDATE_GRUPOS_DATA_REQUEST',
  UPDATE_GRUPOS_DATA_SUCCESS: 'UPDATE_GRUPOS_DATA_SUCCESS',
  UPDATE_GRUPOS_DATA_FAILURE: 'UPDATE_GRUPOS_DATA_FAILURE',
  DELETE_GRUPO_REQUEST: 'DELETE_GRUPO_REQUEST',
  DELETE_GRUPO_FAILURE: 'DELETE_GRUPO_FAILURE',
  DELETE_GRUPO_SUCCESS: 'DELETE_GRUPO_SUCCESS',

  createGruposStart: grupos => ({
    type: gruposActions.CREATE_GRUPOS_START,
    payload: grupos,
  }),
  fetchAllGruposDataStart: () => ({
    type: gruposActions.FETCH_ALL_GRUPOS_DATA_START,
  }),
  fetchAllGruposDataSuccess: grupos => ({
    type: gruposActions.FETCH_ALL_GRUPOS_DATA_SUCCESS,
    payload: grupos,
  }),
  fetchAllGruposDataFailure: error => ({
    type: gruposActions.FETCH_ALL_GRUPOS_DATA_FAILURE,
    payload: error,
  }),
  updateGrupos: grupos => ({
    type: gruposActions.UPDATE_GRUPOS_DATA_REQUEST,
    payload: grupos,
  }),
  updateGruposFailure: error => ({
    type: gruposActions.UPDATE_GRUPOS_DATA_FAILURE,
    payload: error,
  }),
  deleteGrupoRequest: grupo => ({
    type: gruposActions.DELETE_GRUPO_REQUEST,
    payload: grupo,
  }),
  deleteGrupoFailure: error => ({
    type: gruposActions.DELETE_GRUPO_FAILURE,
    payload: error,
  }),
  deleteGrupoSuccess: error => ({
    type: gruposActions.DELETE_GRUPO_SUCCESS,
    payload: error,
  }),
};

export default gruposActions;
