import empresaActions from './actions';

const INITIAL_DATA = {
  data: null,
  loading: true,
  error: null,
};
export default function empresaReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case empresaActions.CREATE_EMPRESA_SUCCESS:
      return {
        ...state,
        data: { empresas: [action.payload, ...state.data.empresas] },
        loadingData: false,
        error: null,
      };
    case empresaActions.CREATE_EMPRESA_FAILURE:
      return {
        ...state,
        data: null,
        loadingData: false,
        error: action.payload,
      };

    case empresaActions.UPDATE_EMPRESA_DATA_SUCCESS:
      let index = state.data.empresas.findIndex(x => x.id === action.payload.id);
      state.data.empresas[index] = action.payload;
      return {
        ...state,
        // data: { empresas: [action.payload, ...state.data.empresas] },
        loadingData: false,
        error: null,
      };
    case empresaActions.UPDATE_EMPRESA_DATA_FAILURE:
      return {
        ...state,
        data: null,
        loadingData: false,
        error: action.payload,
      };
    case empresaActions.FETCH_ALL_EMPRESAS_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case empresaActions.FETCH_ALL_EMPRESAS_DATA_FAILURE:
      return {
        ...state,
        data: null,
        loadingData: false,
        error: action.payload,
      };
    case empresaActions.DELETE_EMPRESA_SUCCESS:
      return {
        ...state,
        // data: action.payload,
        loading: false,
        error: null,
      };
    case empresaActions.DELETE_EMPRESA_FAILURE:
      return {
        ...state,
        // data: null,
        loadingData: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
