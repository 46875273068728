// saga.js
import { all, takeEvery, put, call } from 'redux-saga/effects';
import cargosActions from './actions';
import axios from '@iso/config/axios.config';

function* uploadCSVStart({ payload }) {
  try {
    let { data } = yield call(axios.post, 'api/v1/csv/upload', payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    yield put({
      type: cargosActions.UPLOAD_CSV_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: cargosActions.UPLOAD_CSV_FAILURE,
      payload: true,
    });
  }
}

function* createCargosStart({ payload }) {
  try {
    let { data } = yield call(axios.post, 'api/v1/pesquisa/Cargo/create', payload);
    yield put({
      type: cargosActions.CREATE_CARGOS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: cargosActions.CREATE_CARGOS_FAILURE,
      payload: error.response.data,
    });
  }
}

function* fetchAllCargosDataRequest() {
  try {
    let { data } = yield call(axios.get, 'api/v1/pesquisa/Cargo/fetchAll')
    yield put({
      type: cargosActions.FETCH_ALL_CARGOS_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: cargosActions.FETCH_ALL_CARGOS_DATA_FAILURE,
      payload: error.response.data,
    });
  }
}

function* updateProfileDataRequest({ payload }) {
  try {
    let { data } = yield call(axios.put, 'api/v1/pesquisa/Cargo/update', payload);
    yield put({
      type: cargosActions.UPDATE_CARGOS_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: cargosActions.UPDATE_CARGOS_DATA_FAILURE,
      payload: error.response.data,
    });
  }
}

function* deleteCargosRequest({ payload }) {
  try {
    let { data } = yield call(axios.delete, `api/v1/pesquisa/Cargo/delete/${payload}`);
    yield put({
      type: cargosActions.DELETE_CARGOS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: cargosActions.DELETE_CARGOS_FAILURE,
      payload: error.response.data,
    });
  }
}

export default function* profileSaga() {
  yield all([
    takeEvery(cargosActions.FETCH_ALL_CARGOS_DATA_START, fetchAllCargosDataRequest),
    takeEvery(cargosActions.CREATE_CARGOS_START, createCargosStart),
    takeEvery(cargosActions.UPDATE_CARGOS_DATA_REQUEST, updateProfileDataRequest),
    takeEvery(cargosActions.DELETE_CARGOS_REQUEST, deleteCargosRequest),
    takeEvery(cargosActions.UPLOAD_CSV_REQUEST, uploadCSVStart),
  ]);
}
