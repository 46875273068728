// saga.js
import { all, takeEvery, put, call } from 'redux-saga/effects';
import empresaActions from './actions';
import axios from '@iso/config/axios.config';

function* createEmpresaStart({ payload }) {
  try {
    let { data } = yield call(axios.post, 'api/v1/empresa/createEmpresa', payload);
    yield put({
      type: empresaActions.CREATE_EMPRESA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: empresaActions.CREATE_EMPRESA_FAILURE,
      payload: error.response.data,
    });
  }
}

function* updateEmpresaDataRequest({ payload }) {
  try {
    let { data } = yield call(axios.put, 'api/v1/empresa/updateEmpresa', payload);
    yield put({
      type: empresaActions.UPDATE_EMPRESA_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: empresaActions.UPDATE_EMPRESA_DATA_FAILURE,
      payload: error.response.data,
    });
  }
}

function* deleteEmpresaRequest({ payload }) {
  try {
    let { data } = yield call(axios.delete, `api/v1/empresa/delete/${payload}`);
    yield put({
      type: empresaActions.DELETE_EMPRESA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: empresaActions.DELETE_EMPRESA_FAILURE,
      payload: error.response.data,
    });
  }
}

function* fetchAllEmpresasDataRequest() {
  try {
    let { data } = yield call(axios.get, 'api/v1/empresa/fetchAllEmpresasData')
    yield put({
      type: empresaActions.FETCH_ALL_EMPRESAS_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: empresaActions.FETCH_ALL_EMPRESAS_DATA_FAILURE,
      payload: error.response.data,
    });
  }
}

export default function* profileSaga() {
  yield all([
    takeEvery(empresaActions.CREATE_EMPRESA_START, createEmpresaStart),
    takeEvery(empresaActions.UPDATE_EMPRESA_DATA_REQUEST, updateEmpresaDataRequest),
    takeEvery(empresaActions.DELETE_EMPRESA_REQUEST, deleteEmpresaRequest),
    takeEvery(empresaActions.FETCH_ALL_EMPRESAS_DATA_START, fetchAllEmpresasDataRequest),
  ]);
}
